import { createBrowserHistory } from 'history';
import { parse } from 'qs';

import {
  defaultResponseMiddleware,
  HttpClient,
  RpcClient,
  GrpcClient,
  HttpClientError,
  InternalServerError,
  UnauthorizedError,
} from '@pushwoosh/http-client';
import { createGrpcBridge } from '@pushwoosh/grpc-bridge';

import {
  getPhpApiOrigin,
  getSsoPublicOrigin,
  getSsoClientId,
  getGrpcApiOrigin,
  getGrpcBillingApiOrigin,
  getGrpcJourneyApiOrigin,
} from './helpers/auth.helpers';

type Introspect = {
  readonly username: string;
};

(async (): Promise<void> => {
  try {
    /* HISTORY */
    const history = createBrowserHistory();

    /* HTTP CLIENT */
    // if the current domain is a top-level domain (localhost), then the domain will be localhost
    // in all other cases (site.org, domain.site.org) the domain will be .site.org
    const { hostname } = window.location;
    const domain = hostname.includes('.')
      ? `.${hostname.split('.').slice(-2).join('.')}`
      : hostname;

    const httpClient = new HttpClient(history, {
      urls: {
        authorize: `${getSsoPublicOrigin()}/authorize`,
        token: `${getSsoPublicOrigin()}/token`,
        logout: `${getSsoPublicOrigin()}/logout`,
      },
      clientId: getSsoClientId(),
      cookie: {
        domain,
      },
      basicResponseMiddleware: defaultResponseMiddleware,
      basicErrorMiddleware: async (error): Promise<never> => {
        // if for some reason the error is not related to HttpClientError, then we need to know about it
        if (!(error instanceof HttpClientError)) {
          console.error('not a HttpClientError', error);
        }

        // if an UnauthorizedError appears, it means we need to perform user authorization
        if (error instanceof UnauthorizedError) {
          await httpClient.logout();
          await httpClient.login(); // this is an infinite promise, we won't go beyond this point
        }

        // sometimes the SSO responds with a 500 and ends the user's session, we need to catch this error and start the session again
        if (error instanceof InternalServerError) {
          // if we can't extract the url from the error -> we let it pass further
          if (!error.details?.url) {
            throw error;
          }

          // if it was a request to the token, we start the session again,
          // but before that, we remove the request parameters to the page (otherwise, the old state and code might remain)
          if (error.details.url.startsWith(`${getSsoPublicOrigin()}/token`)) {
            history.replace({ ...history.location, search: '' });
            await httpClient.logout();
            await httpClient.login(); // this is an infinite promise, we won't go beyond this point
          }
        }

        throw error;
      },
    });

    // if the user has authenticated through the authentication server, the server should pass
    // the authorization code and authorization state so that the client can get the access token and refresh token
    const query = parse(window.location.search.slice(1));
    const authorizationCode = typeof query['code'] === 'string' ? query['code'] : null;
    const authorizationState = typeof query['state'] === 'string' ? query['state'] : null;
    const authorization = authorizationCode && authorizationState
      ? { code: authorizationCode, state: authorizationState }
      : null;

    if (!authorization) {
      await httpClient.login();
      return;
    }

    const introspect = await httpClient.authorize<Introspect>(authorization);
    // eslint-disable-next-line no-console
    console.info(`Authorized as ${introspect.username}`);

    const rpcLog = localStorage.getItem('RPC_LOG') === '1';
    const rpcClient = new RpcClient(
      httpClient,
      {
        withAuthorization: true,
        log(info): void {
          if (rpcLog) {
            // eslint-disable-next-line no-console
            console.log(info);
          }
        },
        onException({ error }): void {
          console.error('rpcClient', error);
        },
        enviromentOrigin: getPhpApiOrigin(),
      },
    );

    const grpcHost = localStorage.getItem('GRPC_HOST');
    const grpcClient = new GrpcClient(
      grpcHost || getGrpcApiOrigin(),
      () => httpClient.getAccessToken(),
    );

    const billingGrpcClient = new GrpcClient(
      getGrpcBillingApiOrigin(),
      () => httpClient.getAccessToken(),
    );

    const journeyGrpcClient = new GrpcClient(
      getGrpcJourneyApiOrigin(),
      () => httpClient.getAccessToken(),
    );

    const gpt3GrpcClient = new GrpcClient(
      'https://gpt3-api-grpcweb.svc-nue.pushwoosh.com',
      () => httpClient.getAccessToken(),
    );

    const grpcBridge = createGrpcBridge({
      grpcClient,
      billingGrpcClient,
      journeyGrpcClient,
      gpt3GrpcClient,
    });
    grpcBridge.setEnableLogging(localStorage.getItem('PWGrpcBridgeLogging') === '1');

    const module = await System.import('@pushwoosh/root-config');
    const { startMicroFrontend } = module;

    startMicroFrontend({
      history,
      httpClient,
      rpcClient,
      grpcClient,
      grpcBridge,
      billingGrpcClient,
      journeyGrpcClient,
    });
  } catch (error) {
    console.error('is-crashed', error);
    document.body.classList.add('is-crashed');
  }
})();

if (module.hot) {
  module.hot.accept();
}
